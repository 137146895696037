


























































































































import VueBase from '../../VueBase'
import { Component } from 'vue-property-decorator'
import { ProjectListParams } from './types'
import { formatTimestamp } from '@/utils/utils'

@Component({ name: 'ProjectManage' })
export default class ProjectManage extends VueBase {
  formatTimestamp(date: number | any) {
    return formatTimestamp(date)
  }

  private page = 1
  private pageSize = 20
  private total = 0
  private tableData: Array<any> = []
  private searchObj: any = {
    name: '',
    sort: null,
  }

  btnStatus = {
    生成中: '等待生成',
    已生成: '下载',
    生成失败: '重新生成',
  }

  private async openDownload(row: any) {
    if (row.status === '已生成') {
      window.open(`/api/v1/project/report/download?id=${row.id}`)
    } else {
      if (row.status === '生成失败') {
        const res = await this.services.project.redo(row.id)
        if (res.status === 201) {
          this.$message.success('重新生成中')
          this.getTableData()
        } else {
          this.$message.success(res.msg)
        }
      } else {
        this.$message.warning('报告生成中，请留意消息通知')
      }
    }
  }

  private sortSelect(flag: any) {
    this.searchObj.sort = flag
    this.newSelectData()
  }

  private getLevel(type: number) {
    switch (type) {
      case 1:
        return 'height'
      case 2:
        return 'middle'
      case 3:
        return 'low'
      case 5:
        return 'info'
      default:
        break
    }
  }

  created() {
    this.getTableData()
  }

  private newSelectData() {
    this.page = 1
    this.getTableData()
  }

  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  private async reportDelete(id: any) {
    const res = await this.services.project.reportDelete({ id })
    if (res.status === 201) {
      this.$message.success('报告删除成功')
      this.getTableData()
    }
  }

  private async getTableData() {
    const params: any = {
      page: this.page,
      pageSize: this.pageSize,
      keyword: this.searchObj.name || undefined,
      order_type: this.searchObj.order_type || undefined,
      type_desc: this.searchObj.sort === false ? 1 : 0,
    }
    this.loadingStart()
    const { status, msg, data, page } = await this.services.project.reportList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tableData = data
    this.total = page.alltotal
  }
  private async projectDelete(id: number) {
    this.$confirm(this.$t('views.projectManage.deleteConfirm') as string, '', {
      confirmButtonText: this.$t('views.projectManage.delete') as string,
      cancelButtonText: this.$t('views.projectManage.cancel') as string,
      type: 'warning',
    }).then(async () => {
      this.loadingStart()
      const { status, msg } = await this.services.project.projectDelete({ id })
      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      this.$message({
        type: 'success',
        message: this.$t('views.projectManage.deleteSuccess') as string,
        showClose: true,
      })
      this.getTableData()
    })
  }
}
